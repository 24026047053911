import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';

import GlobalWrapper from '../GlobalWrapper';
import { getRacesData } from '../../Contentful';
import Translator from '../../i18n/Translator';

const OptionWithSubMenu = ({ option, subMenuOptions }) => {
  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button>{option}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="px-1 py-1 ">{subMenuOptions()}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const Header = () => {
  const [racesData, setRacesData] = useState([]);

  const { i18n } = useTranslation();

  const selectedLanguage = i18n.language;

  const portugueseSelected = selectedLanguage === 'pt-PT';

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const _getRacesData = async () => {
      const data = await getRacesData();

      setRacesData(data);
    };

    _getRacesData();
  }, []);

  const RacesSubMenuItems = () => {
    return (
      <>
        {(racesData || [])
          .filter((race) => !!race?.visibleInMenu)
          .map((race) => (
            <Menu.Item key={race.raceId}>
              {({ active }) => (
                <Link to={`/race/${race.raceId}`}>
                  <button
                    className={`${
                      active
                        ? 'bg-[rgb(44,44,44)] text-white'
                        : 'text-[rgb(44,44,44)]'
                    } group flex w-full items-center rounded-md px-2 py-2 text-base`}
                  >
                    {race.raceTitle}
                  </button>
                </Link>
              )}
            </Menu.Item>
          ))}
      </>
    );
  };

  const LangugesSubMenuItems = () => {
    return (
      <>
        <Menu.Item key="pt">
          {({ active }) => (
            <button
              className={`${
                active
                  ? 'bg-[rgb(44,44,44)] text-white'
                  : 'text-[rgb(44,44,44)]'
              } group flex w-full items-center rounded-md px-2 py-2 text-base`}
              onClick={() => handleChangeLanguage('pt-PT')}
            >
              Pt
            </button>
          )}
        </Menu.Item>
        <Menu.Item key="en">
          {({ active }) => (
            <button
              className={`${
                active
                  ? 'bg-[rgb(44,44,44)] text-white'
                  : 'text-[rgb(44,44,44)]'
              } group flex w-full items-center rounded-md px-2 py-2 text-base`}
              onClick={() => handleChangeLanguage('en-US')}
            >
              En
            </button>
          )}
        </Menu.Item>
      </>
    );
  };

  return (
    <GlobalWrapper
      fullScreen
      className={classNames(
        'flex justify-between px-5 md:px-20',
        'bg-[rgb(195,33,35)] text-white text-sm md:text-2xl font-light'
      )}
    >
      <div className={classNames('pr-5')}>
        <Link to="/">
          <img src="/images/logo_axtrail_white.png" alt="AXtrail logo" />
        </Link>
      </div>
      <div className={classNames('flex items-end gap-5')}>
        <OptionWithSubMenu
          option="AXtrail®"
          subMenuOptions={RacesSubMenuItems}
          linkTo=""
        />
        <Link to={`/race/axtrail-series`}>
          <div className="cursor-pointer">AXtrail® Series</div>
        </Link>
        <a
          href="https://stopandgo.net/events/axtrail2024"
          target="_blank"
          rel="noreferrer"
        >
          <div className="cursor-pointer">
            <Translator path="registrations" />
          </div>
        </a>
        <OptionWithSubMenu
          option={portugueseSelected ? 'Pt' : 'En'}
          subMenuOptions={LangugesSubMenuItems}
          linkTo=""
        />
      </div>
    </GlobalWrapper>
  );
};

export default Header;
