import classNames from 'classnames';

const GlobalWrapper = ({ fullScreen, className, children }) => {
  return (
    <div
      className={classNames(
        fullScreen ? 'w-full' : 'max-w-[1200px]',
        'mx-auto',
        className
      )}
    >
      {children}
    </div>
  );
};

export default GlobalWrapper;
