import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { keys, propOr } from 'ramda';

import { getHomepageData, getRacesData } from '../../Contentful';
import Translator from '../../i18n/Translator';

import GlobalWrapper from '../../Components/GlobalWrapper';
import RaceItem from './Components/RaceItem';

import { parseImage } from '../../utils/parseImage';

const exist = Boolean;

const getBanner = propOr([], 'banner');

const HomeComponent = () => {
  const [homepageData, setHomepageData] = useState({});
  const [racesData, setRacesData] = useState([]);

  const partnersList = useMemo(() => {
    const partnersIndexList = keys(homepageData)
      .filter((key) => key.startsWith('partnersLabel'))
      .map((key) => Number(key.replace('partnersLabel', '')));

    return partnersIndexList.reduce((acc, index) => {
      const logos = homepageData[`partnersLogos${index}`];

      if (!logos?.length) return acc;

      const partner = {
        label: homepageData[`partnersLabel${index}`],
        logos: logos.map((logo) => parseImage(logo)),
      };

      return [...acc, partner];
    }, []);
  }, [homepageData]);

  useEffect(() => {
    const _getHomepageData = async () => {
      const data = await getHomepageData();
      setHomepageData(data);
    };

    const _getRacesData = async () => {
      const data = await getRacesData();

      setRacesData(data);
    };

    _getHomepageData();
    _getRacesData();
  }, []);

  return (
    <GlobalWrapper fullScreen>
      {exist(getBanner(homepageData).length) &&
        getBanner(homepageData).map((img) => (
          <img src={img.src} alt={img.alt} className="w-full" key={img.src} />
        ))}

      {/* Races */}
      <div className={classNames('flex flex-col items-center py-5')}>
        <h1 className="text-4xl py-14">
          <Translator path="theRaces" />
        </h1>
        <div
          className={classNames(
            'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10'
          )}
        >
          {(racesData || []).map((race) => RaceItem(race))}
        </div>
      </div>

      {/* Partners */}
      {partnersList.length > 0 &&
        partnersList.map((partner) => (
          <div className={classNames('flex flex-col items-center py-5')}>
            <h1 className="text-4xl py-14">{partner.label}</h1>
            <div
              className={classNames(
                'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10'
              )}
            >
              {partner.logos.map((logo) => (
                <img src={logo.src} alt={logo.alt} />
              ))}
            </div>
          </div>
        ))}
    </GlobalWrapper>
  );
};

export default HomeComponent;
